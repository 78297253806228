.Nav {
    // box-shadow: inset 0px 0px 0px 5px red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    transition: all 1s;

    &.high-vis {
        background-color: rgba(40, 44, 52, 0.8);
    }

    a {
        color: #61dafb;

        &.NavLink {
            color: #fdfdfd;
            text-decoration: none;
            margin-right: 1em;
            // transform: rotate(90deg);
        }
    }

    .NavLink {
        h2 {
            font-size: 0.825em;
        }
    }
}
