.RedditPostList {
    font-size: 3vmin;
    overflow: auto;
    height: auto;
}

.RedditPost {
    border-bottom: 1px solid #999;
    margin: 1em 3em;
    padding: 0.5em 0;
}