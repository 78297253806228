.Marquee {
    // box-shadow: inset 0px 0px 0px 4px blue;
    display: block;
    height: 20vmin;
    width: 40vmin;
    margin-right: auto;

    .Marquee-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // box-shadow: inset 0px 0px 0px 4px magenta;
        position: absolute;
        top: 0;
        left: 0;
        height: 20vmin;
        width: 40vmin;
        transition: width 1s, height 1s;
        z-index: 999;

        pointer-events: none;
        a {
            pointer-events: all;
        }
    }

    .Marquee-logo {
        width: 40vmin;
        height: auto;
        pointer-events: none;
        transition: width 1s, height 1s;
        z-index: 999;
    }

    &.bigMode {
        pointer-events: none;

        .Marquee-inner {
            width: 100vw;
            height: 100vh;
            a {
                pointer-events: none;
            }
        }

        .Marquee-logo {
            width: 90vw;
            max-width: calc(90vh * 1090 / 627);
            left: 5vw;
        }
    }
}