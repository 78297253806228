.Wedding {
    max-height: 100vh;  /* change this to whatever you want */
    width: auto;

    .download-info {
        margin: 0 2em;
        font-size: 0.5em;
    }
}

.react-images__view .react-images__view-image--isModal {
    max-height: 100vh;  /* change this to whatever you want */
    width: auto;
}