body {
  background-color: #0d0d0d;
}

.App {
  // font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
}

.App-header {
  min-height: 20vmin;
  max-height: 20vmin;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-content {
  color: white;
  // box-shadow: inset 0px 0px 0px 4px blue;
  position: relative;
  // height: calc(100vh - 50vmin);
  font-size: calc(10px + 4vmin);
  // margin-top: 2em;
  // top: 10vmin;
  height: calc(100vh - 20vmin);
  bottom: 0;
  overflow: auto;
  padding-top: 1em;
  padding-bottom: 2em;
  box-sizing: border-box;
}

.App-background {
  display: flex;
  justify-content: center;

  overflow: hidden;
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: -100;

  video {
    position: absolute;
    transition: opacity 2s ease-in-out;
    width: 100vw;
    min-width: calc(100vh * 4 / 3);
    top: 50%;
    transform: translateY(-50%);
  }
}