.About {
    .imgConstruction {
        width: 90%;
    }

    .imgConstructionSpinny1 {
        width: 1em;
    }

    .imgConstructionSpinny2 {
        width: 2em;
    }
}